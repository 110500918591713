import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import styles from "./style.module.scss";
import WalkerIcon from "./icons/walkerIcon";
import ShelikofIcon from "./icons/shelikofIcon";
import StaxIcon from "./icons/staxIcon";
import ShareSlider from "../sharedSlider";
import useWindowSize from "@hooks/useWindowSize";
const list = [
    {
        description:
            "Several Brands have consistently supplied high-quality leads and excellent customer service. The team has been very receptive and prompt in responding to any worries we have had. We look forward to continuing to scale our campaigns with them.",
        iconName: <WalkerIcon />,
        name: "Blake Holiday, Sr. Affiliate Manager, Walker Advertising.",
    },
    {
        description:
            "I've had the pleasure of working with Several Brands for years. Their team is comprised of skilled professionals who are passionate and experienced in what they do. I'd recommend Several Brands to anyone looking for high-quality leads and a solid partner.",
        iconName: <ShelikofIcon />,
        name: "Noah Fields, Co-Founder, Shelikof Marketing.",
    },
    {
        description:
            "Working with the Several Brands team has been nothing short of exceptional. Since the beginning of our partnership, it's been clear that our team's success was their top priority, and they have been willing to experiment with new ideas and strategies in an effort to help grow our partnership into one that's profitable for both sides. We are so grateful for their transparency.",
        iconName: <StaxIcon />,
        name: "Kyle Maley, Affiliate Marketing Manager, Stax.",
    },
];
export default function WhatClientsSay() {
    const windowSize = useWindowSize();
    const mainSectionDevRef = useRef(null);
    const isInView = useInView(mainSectionDevRef, { once: true });
    const firstEl = useAnimation();
    const secondEl = useAnimation();

    useEffect(() => {
        if (isInView) {
            firstEl.start("visible").catch(() => {});
            secondEl.start("visible").catch(() => {});
        }
    }, [isInView]);
    return (
        <motion.section
            ref={mainSectionDevRef}
            className={styles["bgStyle"]}
            variants={{
                visible:
                    windowSize.width < 1024
                        ? { y: [150, 0], opacity: [0, 1] }
                        : {
                              x: [-1000, 0],
                          },
            }}
            transition={{ duration: 1.5 }}
            animate={firstEl}
        >
            <div className="3xl:max-w-[700px] lg:max-w-[450px] px-4 lg:px-0 z-10 lg:mt-10 ">
                <h2 className="font-bold 3xl:text-7xl lg:text-5xl text-3xl lg:leading-[54px] leading-9  ">
                    What Clients Say
                </h2>
                <p className="text-[#351C4F] 3xl:mt-5 mt-3 3xl:text-2xl 3xl:leading-10 text-lg leading-8 font-light">
                    At SeveralBrands, our goal is to help your business expand
                    its reach, engage with your target audience, and convert
                    leads into valuable customers. To give you a better idea of
                    our services, let's hear from those who have experienced the
                    benefits firsthand.
                </p>
            </div>

            <motion.div
                variants={{
                    visible:
                        windowSize.width < 1024
                            ? {}
                            : {
                                  x: [2000, 0],
                                  transition: { duration: 1.5 },
                              },
                }}
                animate={secondEl}
                className="sm:flex z-10  items-center justify-center   "
            >
                <ShareSlider deferentStyle={false} list={list} perPage={1} />
            </motion.div>
        </motion.section>
    );
}
